'use client';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Locale } from '../../../i18n-config';
import dynamic from 'next/dynamic';

import Section11 from '@/components/home/Section11/Section11';

import SiteSocialNetworkComponent from '@/components/home/SiteSocialNetworkComponent';
import Section5 from '@/components/home/Section5/Section5';
import Section10 from '@/components/home/Section10/Section10';
import TransformYourStrategy from '@/components/home/components/TransformYourStrategy';
import ChooseYourApproach from '@/components/home/components/ChooseYourApproach';
import BuildSmarter from '@/components/home/components/BuildSmarter';
import FooterBaner from '@/components/FooterBanner';
import { BiTimeFive } from 'react-icons/bi';

const Section2 = dynamic(() => import('@/components/home/Section2/Section2'), { ssr: false });

const Client = () => {
  const params = useParams<{ lang?: string }>();

  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  return (
    <div className="home-page bg-eighteenColor">
      <Section11 t={t} params={params} />
      <div className="tw-w-full tw-flex tw-justify-center home-page">
        <div className="tw-px-4 tw-container  lg:tw-px-0">
          {/* <Section2 t={t} />
          <CompareSection t={t} />
         <Section3 t={t} /> */}
          {/* <Section6 t={t} /> */}
          {/* <Section4 t={t} /> */}
          <Section2 t={t} />
          {/* <section className="tw-mt-16">
            <TrustedPoweredGiants t={t} />
          </section> */}
          <TransformYourStrategy t={t} />
          <ChooseYourApproach t={t} />
          <BuildSmarter t={t} />
          {/* <CompareSection t={t} /> */}
          <section className="tw-my-8">
            <Section5 t={t} />
          </section>
          <section className="tw-mt-16 ">
            <Section10 t={t} />
          </section>
          <section className="tw-my-10">
            <FooterBaner
              title={t('GrowWithUs/key1')}
              description={''}
              buttonTitle={t('GrowWithUs/key2')}
              linkToSite="/plans"
              moreDetails={
                <div className="tw-flex lg:tw-flex-row tw-flex-col lg:tw-items-center tw-items-start text-white font-sofiaSans lg:tw-gap-10 tw-gap-4 lg:tw-justify-end tw-justify-center tw-w-full">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <span className="font-sofiaSans fs-5 tw-flex tw-items-center tw-justify-center tw-gap-1">
                      <svg
                        style={{ fill: 'white' }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#fffff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.26044 23.0854L21.6397 2.39572L19.7387 0.798828L17.0989 3.94141H4C2.897 3.94141 2 4.83841 2 5.94141V17.9414C2 18.9356 2.7288 19.7625 3.68035 19.9159L2.35938 21.4885L4.26044 23.0854ZM5.33892 17.9414H4V11.9414H10.3789L5.33892 17.9414ZM13.7389 7.94141L15.4189 5.94141H4V7.94141H13.7389ZM20 7.94141H18.569L15.2646 11.9414H20.001L20.002 17.9414H10.3081L8.65592 19.9414H20C21.103 19.9414 22 19.0444 22 17.9414V5.94141C22 5.38304 21.7701 4.87746 21.4 4.51432L20 6.2091V7.94141Z"
                          fill="#fffff"
                        />
                      </svg>
                      <span className="lg:tw-text-center tw-text-left tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                        {t('home/SiteCustomerSupport/text-01')}
                      </span>
                    </span>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <span className="font-sofiaSans fs-5 tw-flex tw-items-center tw-justify-center tw-gap-1">
                      <BiTimeFive className="fs-2 tw-text-white" />
                      <span className="lg:tw-text-center tw-text-left tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                        {t('home/SiteCustomerSupport/text-02')}
                      </span>
                    </span>
                  </div>
                </div>
              }
            />
          </section>
        </div>
      </div>
      <SiteSocialNetworkComponent />
    </div>
  );
};

export default Client;
